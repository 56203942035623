@import "animate.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import HyperBlobOutline */
@font-face {
  font-family: "HyperBlobOutline";
  src: local("HyperBlobOutline"),
    url("./fonts/HyperBlobOutline-OVj6o.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Import MekkoOutline */
@font-face {
  font-family: "MekkoOutline";
  src: local("MekkoOutline"),
    url("./fonts/MekkoOutline-ovXOB.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Import Chibolddemo */
@font-face {
  font-family: "Chibolddemo";
  src: local("Chibolddemo"),
    url("./fonts/Chibolddemo-1GD7g.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff00 !important;
}
html {
  background-color: #ffffff00 !important;
}

.text-justify {
  text-align: justify;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* background: rgb(255, 255, 255); */
  background: rgba(255, 255, 255, 0.084);
  width: 100%;

  margin: 0;
  padding: 0;
}

footer-bg {
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.footer-text {
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 10px;
  color: #dadada;
}

/* Previous */

.outer-box {
  /* background-color: rgba(106, 91, 91, 0.373); */
  width: 100% !important;
  margin: 0;
  padding: 0;
  /* display: flex; */
  justify-content: center;
}
.container {
  /* max-width: 1200px !important; */
  /* filter: blur(8px); */

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway", sans-serif;
  /* padding-top: 1rem; */
  border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
  .container {
    margin: 0;
    border-radius: 0;
  }
  #root {
    padding: 0;
  }
}

/* Anchors */

.heading2 {
  text-shadow: 0px 0px 15px rgb(255, 0, 0);
  color: white;
}

p {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.15),
    1px 1px 1px rgba(0, 0, 0, 0.1);
}

a {
  /* background: -webkit-linear-gradient(
    rgb(255, 250, 250),
    rgba(255, 255, 255, 0.566)
  ); */
  color: rgba(255, 255, 255, 0.9);
  /* 3D text effect using multiple text shadows */
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4),
    /* subtle inner shadow */ 2px 2px 0px rgba(0, 0, 0, 0.1),
    3px 3px 0px rgba(0, 0, 0, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  /* text-shadow: 1px 1px rgba(145, 145, 145, 0.325); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 1000;
}

.card-footer a {
  background: -webkit-linear-gradient(rgb(255, 194, 194), rgb(184, 173, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 1000;
}

a:hover {
  text-decoration: none;
  /* background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: rgb(255, 255, 255);
  /* 3D text effect using multiple text shadows */
  text-shadow: 1px 1px 0px rgba(255, 0, 0, 0.2),
    /* subtle inner shadow */ 2px 2px 0px rgba(17, 0, 255, 0.2),
    3px 3px 0px rgba(255, 0, 0, 0.2), 4px 4px 0px rgba(255, 0, 0, 0.2);
  font-weight: 1000;
}

/* Navigation */
#icons img {
  width: 2em;
  padding: 3px;
  border-radius: 0;
  margin: 5px;
}

#icons li {
  list-style: none;
}

#icons {
  position: fixed;
  top: 1vw;
  right: 1vw;
  z-index: 10;
}

#icons img {
  transition: all 0.2s ease-in;
}
#icons img:hover {
  transform: scale(1.3);
}

.footer-icons {
  margin-top: 15px;
  display: none;
}

@media only screen and (max-width: 1300px) {
  #icons img {
    display: none;
  }

  .footer-icons {
    display: flex;
    justify-content: center;
  }

  .footer-icons img {
    margin: 5px 10px;
    width: 2em;
  }

  .footer-icons img {
    transition: all 0.2s ease-in;
  }
  .footer-icons img:hover {
    transform: scale(1.3);
  }
}

/* Intro section */

.jumbotron {
  /* background-color: rgba(0, 0, 0, 0.495) !important; */
  width: 100%;
  display: flex !important;
  box-sizing: border-box;

  /* padding-inline: 20px; */
}

.mePhotoContainer {
  /* display: flex; */
  position: relative;
  bottom: -105px;

  /* position: sticky; */
  /* align-items: flex-end;
  justify-content: flex-end; */
  padding: 0;
  margin: 0;
  margin-right: 0;
  /* transform: rotate(-90deg); */
}

#mePhoto {
  width: 18rem;
  display: block;
}

@media only screen and (max-width: 1100px) {
  #mePhoto {
    transform: rotate(-180deg);
  }

  .mePhotoContainer {
    bottom: 24px;
  }
}

@media only screen and (max-width: 990px) {
  #mePhoto {
    width: 10rem;
  }
}

@media only screen and (max-width: 768px) {
  #mePhoto {
    display: none;
  }
}

/* My projects section */

.sectionTitle {
  font-size: 4em;
  font-family: "Chibolddemo", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  margin-block: 1rem 0;
  text-align: center;
  /* 3D text effect using multiple text shadows */
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2),
    /* subtle inner shadow */ 2px 2px 0px rgba(0, 0, 0, 0.2),
    3px 3px 0px rgba(0, 0, 0, 0.2), 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 2rem;
  margin: 0.7rem 0;
}

.ci {
  width: 100%;
  padding: 0;
  /* max-height: 250px; */
  /* background-color: rgb(255, 255, 255); */
  object-fit: scale-down;
  margin: 0;
}

.card {
  transition: all 0.2s ease-in;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.319) !important;
  color: rgb(255, 255, 255);
  border: 0 !important;
}

.card:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 768px) {
  .ci {
    background-color: rgba(255, 255, 255, 0);
  }
}

.card {
  /* background-color: rgba(0, 0, 0, 0.495) !important; */
  color: rgb(255, 255, 255);
  border: 0 !important;
}

/* @media only screen and (min-width: 1050px) {
  .ci{
    display: block;
    box-sizing: border-box;
    width: 320px !important;
    height: 212px;
    margin: auto;
    object-fit: scale-down;
  }
} */
@media only screen and (min-width: 784px) {
  /* .card-img img {
    width: 100%;
    border: 0;
    padding: 0;z
    margin: 0;
  }

  .card-img {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
  } */

  .card-footer {
    margin-top: auto; /* Pushes footer to the bottom */
    padding: 10px;
    text-align: center;
  }

  .project {
    min-width: 360px;
    max-width: 32%;
  }
}
.card-text {
  padding: 0 20px;
  margin-top: 0.5em;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}

p.card-text {
  margin-top: 0;
}

h5.card-text {
  font-size: 1.2rem;
}

/* About me */

.float {
  display: block;
  float: right;
  width: 40px;
}

.projectContainer {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.project {
  display: flex;
  flex: 1 1 30%; /* Allows flexibility in width and ensures equal height */
  min-width: 360px;
}

@media only screen and (min-width: 784px) and (max-width: 1159px) {
  .projectList {
    justify-content: space-around;
  }

  .project {
    flex: 1 1 30%;
  }
}

/* Animate */

:root {
  --animate-duration: 2s;
}

.text-center {
  align-self: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutText {
  padding: 2rem;
}

.cardAbout {
  display: flex;
  justify-content: center;
}

.techList {
  padding-left: 50px;
}
