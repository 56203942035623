@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Parisienne&family=Pacifico&family=Satisfy&family=Alex+Brush&family=Allura&family=Yellowtail&family=Rouge+Script&family=Amatic+SC&display=swap");
/* Flickering animation */
/* .outerBox {
  width: 100%;
  position: relative;
  padding: 2vh 3vh 0 3vh;
  display: flex;
  justify-content: center;
} */
.intro {
  display: flex;
  justify-content: center;
  /* padding: 0 !important; */

  color: rgb(255, 255, 255);
  /* animation: flash-txt 8.5s linear 0s, flash-txt 8.5s linear 11s infinite; Run immediately and then after a delay */
  text-shadow: 2px 2px 18px rgb(4, 107, 252);
}

.innerIntro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: min-content; */
  /* flex-wrap: wrap-reverse; */
  /* max-width: 60%; */
  /* padding-block: 8vh; */
  z-index: 2;
  /* justify-content: flex-start; */
}

.introHey {
  margin-block: 2vh;
}

.intro-name {
  margin-block: 2vh;
  display: flex;
}

#intro h1 {
  font-size: 3rem;
}

#intro h2 {
  font-size: 3.9rem;
}

#intro2 {
  /* display: flex;
  flex-wrap: wrap;
  text-wrap: wrap;
  width: auto; */
  /* max-width: fit-content;
  min-width: min-content; */
  font-size: 2.9rem !important;
  margin-block: 2vh;
  padding-bottom: 2vh;
}

#myName {
  background: -webkit-linear-gradient(rgb(150, 101, 241), rgb(228, 146, 255));
  text-shadow: -4px -2px rgb(255, 255, 255);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 1000;
  text-decoration: none;
  margin-left: 1rem;
}

#myName:hover {
  background: -webkit-linear-gradient(rgb(255, 29, 29), rgb(208, 69, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 1000;
}

/* Flickering animation keyframes */
@keyframes flash-txt {
  0% {
    text-shadow: 2px 2px 18px rgb(0, 255, 81);
    /* color: rgb(0, 0, 0); */
  }
  18% {
    text-shadow: 2px 2px 18px rgb(4, 107, 252);
    color: white;
  }
  20% {
    text-shadow: none;
    color: white;
  }
  22% {
    text-shadow: 2px 2px 18px rgb(252, 4, 231);
    /* color: rgb(0, 0, 0); */
  }
  24% {
    text-shadow: none;
    color: white;
  }
  25% {
    text-shadow: 2px 2px 18px rgb(4, 252, 83);
    color: white;
  }
  53% {
    text-shadow: 2px 2px 18px rgb(4, 107, 252);
    color: white;
  }
  55% {
    text-shadow: none;
    color: white;
  }
  57% {
    text-shadow: 2px 2px 8px rgb(252, 4, 231);
    /* color: rgb(0, 0, 0); */
  }
  100% {
    text-shadow: 2px 2px 8px rgb(4, 107, 252);
    color: white;
  }
}

@-webkit-keyframes flash-txt {
  0% {
    text-shadow: 2px 2px 18px rgb(4, 252, 83);
    color: white;
  }
  18% {
    text-shadow: 2px 2px 18px rgb(4, 107, 252);
    color: white;
  }
  20% {
    text-shadow: none;
    color: white;
  }
  22% {
    text-shadow: 2px 2px 18px rgb(252, 4, 231);
    color: white;
  }
  24% {
    text-shadow: none;
    color: white;
  }
  25% {
    text-shadow: 2px 2px 18px rgb(4, 252, 83);
    color: white;
  }
  53% {
    text-shadow: 2px 2px 18px rgb(4, 107, 252);
    color: white;
  }
  55% {
    text-shadow: none;
    color: white;
  }
  57% {
    text-shadow: 2px 2px 8px rgb(252, 4, 231);
    color: white;
  }
  100% {
    text-shadow: 2px 2px 8px rgb(4, 107, 252);
    color: white;
  }
}

/* Main container styles */
.jumbotron {
  position: relative; /* So that absolutely positioned children are relative to this */
  overflow-y: visible;
  background: transparent; /* Transparent background so the frothy layer shows */
}
.outerBox {
  position: relative;
  display: flex;
  padding-top: 20px;
  overflow-y: visible;
  overflow-x: hidden;
  /* justify-items: center;
  align-items: center;
  justify-content: center; */
  /* gap: 0; */
  width: 100%;
  height: 100%;
  background: transparent;
}

/* These two elements will expand to fill the remaining space on the left and right */
.outerBoxLeftBlur,
.outerBoxRightBlur {
  flex-grow: 1;
  height: 400px;
  pointer-events: none;
  z-index: 2;
  min-width: 24px;
}

/* Left blur: gradient from opaque at the edge to transparent inward */
.outerBoxLeftBlur {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), transparent);
  backdrop-filter: blur(2px);
}

/* Right blur: gradient from opaque at the edge to transparent inward */
.outerBoxRightBlur {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), transparent);
  backdrop-filter: blur(2px);
}

/* Bubble animations remain unchanged */

.bubbles {
  position: absolute;
  width: 100%;
  height: 95%;
  pointer-events: none; /* Prevent the bubbles from interfering with interactions */
  z-index: 1;
}

.bubble {
  position: absolute;
  border-radius: 50%;
  left: 45%;
  padding: 20px 30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  white-space: nowrap;
  /* background: radial-gradient(
    ellipse at center,
    rgba(0, 42, 255, 0.09) 0%,
    rgba(191, 0, 255, 0) 60%
  ); */
  /* backdrop-filter: blur(5px); */
  animation: float-right 5s infinite linear;
  opacity: 0;
  font-weight: 800;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.05);
}

/* .bubble-bg {
  position: absolute;
  border-radius: 50%;
  left: -30px;
  top: -20px;
  padding: 20px 30px;
  color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  white-space: nowrap;
  background: radial-gradient(
    ellipse at center,
    rgba(138, 36, 255, 0.156) 0%,
    rgba(228, 146, 255, 0) 80%
  );
  filter: blur(15px);
} */

.bubble:nth-child(odd) {
  animation: float-left 5s infinite linear;
}

@keyframes float-right {
  0% {
    transform: translateX(-70vw);
    /* opacity: 0; */
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(55vw);
    /* opacity: 0; */
  }
}

@keyframes float-left {
  0% {
    transform: translateX(55vw);
    /* opacity: 0; */
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(-55vw);
    /* opacity: 0; */
  }
}
