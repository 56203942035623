/* New frothy floating background with rounded corners and margin */
.intro-frothy-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  /* Inner shadows to create a 3D window-like effect */
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.3),
    inset 0 -4px 10px rgba(255, 255, 255, 0.2);
  animation: float 6s ease-in-out infinite;
  z-index: 0;
}

/* Keyframes for gentle floating (up-and-down) animation */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}
